<template>
  <section class="Add-Content">
    
    <div class="page-header">
      <h3 class="page-title">Add Role</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/roles/roles" class="text-primary">Roles
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add Role</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="addRole">
              <div class="mb-1">
                <!-- <label class="border-order-label">Add Role</label> -->
                <div  >
                  <div class="pt-1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Type</label>
                              <multiselect
                                v-model="newRole.type"
                                :multiple="false"
                                :options="['cms','client']"
                              ></multiselect>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Role</label>
                              <input v-model="newRole.role" type="text" class="form-control" />
                            </div>
                          </div>
                          <div v-if="newRole.type == 'cms'" class="col-12">
                            <div class="form-group">
                              <label for="name">CMS Permissions</label>
                              <multiselect
                                v-model="newRole.permissions"
                                :multiple="true"
                                :options="cmsPerm"
                              ></multiselect>
                            </div>
                          </div>
                          <div v-if="newRole.type == 'client'" class="col-12">
                            <div class="form-group">
                              <label for="name">Client Permissions</label>
                              <multiselect
                                v-model="newRole.permissions"
                                :multiple="true"
                                :options="clientPerm"
                              ></multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div class="col-12 pt-1">
                        <button class="btn btn-primary btn-md btn-block">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import vue2Dropzone from "vue2-dropzone";
import { roleApi } from "../../api";
import Multiselect from "vue-multiselect";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import chooseImage from "../../components/chooseImage";
export default {
  name: "add-role",
  components: {
     Multiselect,vue2Dropzone, chooseImage,
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${mediaApiURL}/file/upload`,
      },
      newRole: {
        role: "",
        type: "",
        permissions: [],
      },
      
      submitted: false,
      
      imageApiURL, bannerImg: "",
      perm_empty: [],
      cmsPerm: [],
      clientPerm: [],
    };
  },
  validations: {
    newRole: {
      role: { required },
      type: { required },
    },
  },
  mounted() {
    this.getCmsPermissions()
    this.getClientPermissions()
    console.log(this.$router)
  },
  methods: {
    async getCmsPermissions() {
      this.submitted = true;

      
      const { data } = await roleApi.getPermissions();
      this.cmsPerm = data.data.data.cms_permissions
      
    },
    async getClientPermissions() {
      this.submitted = true;

      
      const { data } = await roleApi.getPermissions();
      this.clientPerm = data.data.data.client_permissions
      
    },
    async addRole() {
      this.perm_empty = [];

      this.submitted = true;

      if (this.permissions) {
        this.permissions.forEach((value) => {
          let permissions = value.id;
          if (!this.perm_empty.includes(permissions)) this.perm_empty.push(permissions);
        });
        this.newRole.permissions = this.perm_empty;
      }
      const data  = await roleApi.addRole({...this.newRole});
      
      this.$router.push("/roles/roles");

    },
  },
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style scoped>
.border-order-label {
  background: #fff;
  margin-left: 22px;
  padding: 0px 9px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 15px;
}

.form-group label {
  font-size: 12px;
}

.border {
  border: 1px solid #2125299e !important;
}

.dropzone {
  padding: 0px 20px !important;
}
</style>